


















































































import { Component } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import { ColorSchemaSelector, HAlignmentSelector } from '../../../molecules'
import { FormFieldset } from '../../../atoms'
import { MarkdownEditor } from '../../../molecules/MarkdownEditor'

import { AbstractModuleForm } from '../../_abstract'

import { headingContentFactory } from '../Heading.factory'
import { HeadingFont, HeadingModule, HeadingModuleContent } from '../Heading.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (edited)
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
@Component<HeadingModuleForm>({
  name: 'HeadingModuleForm',
  components: { ColorSchemaSelector, FormFieldset, HAlignmentSelector, MarkdownEditor }
})
export class HeadingModuleForm extends AbstractModuleForm<HeadingModule> {
  public initialContent: HeadingModuleContent = headingContentFactory()

  /**
   * Heading levels of `Heading`
   */
  public get headingOptions (): DashmixSelectItem[] {
    const options = []

    for (let i = 1; i < 7; i++) {
      options.push({
        label: `${i}`,
        value: i
      })
    }

    return options
  }

  public get fontOptions (): DashmixSelectItem[] {
    return Object.values(HeadingFont).map((value: HeadingFont) => {
      return {
        label: this.$t(`forms.Heading.font.option.${value}`) as string,
        value
      }
    })
  }

  public get isZoomValid (): boolean | undefined {
    if (!this._content.zoom) {
      return undefined
    }
    return this._content.zoom > 0
  }

  public get zoomHelper (): string {
    if (this.isZoomValid === false) {
      return 'forms.Heading.zoom.error'
    }
    return 'forms.Heading.zoom.helpers'
  }
}

export default HeadingModuleForm
