















































import { Component } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import { ColorSchemaSelector, HAlignmentSelector, LinkForm } from '../../../molecules'
import { FormFieldset } from '../../../atoms'

import { AbstractModuleForm } from '../../_abstract'

import { BUTTON_MODULE_THEME } from '../Button.config'
import { buttonContentFactory } from '../Button.factory'
import { ButtonModule, ButtonModuleContent } from '../Button.contracts'

/**
 * Form component for the `Button` module.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Component<ButtonModuleForm>({
  name: 'ButtonModuleForm',
  components: { ColorSchemaSelector, FormFieldset, HAlignmentSelector, LinkForm }
})
export class ButtonModuleForm extends AbstractModuleForm<ButtonModule> {
  /**
   * @inheritDoc
   */
  public initialContent: ButtonModuleContent = buttonContentFactory()

  /**
   * Value for the `options` prop of the `<DsSelect>` that controls the `theme` property.
   *
   * @see ButtonModuleContent.theme
   */
  public get themeOptions (): DashmixSelectItem[] {
    return Object.entries(BUTTON_MODULE_THEME).map(([name, value]) => ({
      label: this.$t(`formHelpers.theme.options.${name}`).toString(),
      value
    })).sort((a, b) => a.label > b.label ? 1 : -1)
  }
}

export default ButtonModuleForm
