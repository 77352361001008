



























import { Component } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

import { ColorSchemaSelector, HAlignmentSelector } from '../../../molecules'
import { MarkdownEditor } from '../../../molecules/MarkdownEditor'

import { AbstractModuleForm } from '../../_abstract'

import { textContentFactory } from '../Text.factory'
import { TextModule, TextModuleContent } from '../Text.contracts'

/**
 * TODO: Document all methods and properties.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (original)
 */
@Component<TextModuleForm>({
  name: 'TextModuleForm',
  components: { ColorSchemaSelector, HAlignmentSelector, MarkdownEditor },
  created () {
    if (this._content.extendedContent && this._content.extendedContent.length > 0) {
      this.useExtendedText = true
    }
  }
})
export class TextModuleForm extends AbstractModuleForm<TextModule> {
  public icons = DashmixIconName
  public initialContent: TextModuleContent = textContentFactory()

  /**
   * Property determine wherever extendedText is used or not.
   */
  public useExtendedText: boolean = false

  /**
   * Property to store extended text value.
   */
  public storedExtendedText: string = ''

  /**
   * Method to change value of `useExtendedText`.
   */
  public onUseExtendedTextChange (value: boolean): void {
    this.useExtendedText = value

    this.changeExtendedTextValue(value)
  }

  /**
   * Method to delete and add to store or add from store extendedContent value
   * depending on the value of `useExtendedText`.
   */
  private changeExtendedTextValue (useExtendedText: boolean): void {
    const contentCopy = { ...this._content }

    if (!useExtendedText) {
      this.storedExtendedText = contentCopy.extendedContent ?? ''
      delete contentCopy.extendedContent
    } else {
      contentCopy.extendedContent = this.storedExtendedText
    }

    this._content = contentCopy
  }
}

export default TextModuleForm
