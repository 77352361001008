




















import { Component } from 'vue-property-decorator'

import { FormFieldset } from '../../../atoms'
import { ImageForm, LinkForm } from '../../../molecules'

import { AbstractModuleForm } from '../../_abstract'

import { imageContentFactory } from '../Image.factory'
import { ImageModule, ImageModuleContent } from '../Image.contracts'

/**
 * TODO: Document all methods and properties.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Component<ImageModuleForm>({
  name: 'ImageModuleForm',
  components: { FormFieldset, ImageForm, LinkForm }
})
export class ImageModuleForm extends AbstractModuleForm<ImageModule> {
  public initialContent: ImageModuleContent = imageContentFactory()
}

export default ImageModuleForm
