




















































































import { AsyncComponent } from 'vue'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { v4 as uuid } from 'uuid'

import { ExposesRelatedRecord, ResolvesRelatedAsync } from '../../../../../services'
import { log } from '../../../../../support'
import { PickerCallback, UnresolvedImage } from '../../../../../contracts'

import { FormFieldset } from '../../../../atoms'
import { ImageForm, GalleryForm, MapSelector, Iterator } from '../../../../molecules'

import { DEFAULT_VARIANT, HeadingAddon, LinkAddon, SearchAddon, TextAddon } from '../../addons'
import {
  HeroModuleAddon,
  HeroModuleAddonType,
  HeroModuleAnimationContent,
  HeroModuleSlideContent, HeroModuleVersion
} from '../../Hero.contracts'
import { heroModuleAddonTypeFormComponentRegistry } from '../../Hero.config'

/**
 * Partial form component to collect data for Hero slide
 * Used as partial in `HeroModuleForm`
 * @see HeroModuleSlideContent
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'HeroSlideForm',
  components: { ImageForm, GalleryForm, MapSelector, FormFieldset, Iterator }
})
export class HeroSlideForm extends Vue {
  /**
   * Single slide in the `Hero` module.
   */
  @PropSync('slide', { type: Object, required: true })
  public _slide!: HeroModuleSlideContent | HeroModuleAnimationContent

  /**
   * Callback used by form to pick relate.
   */
  @Prop({ type: Function, required: false })
  public readonly pickRelated!: PickerCallback

  /**
   * Service capable of resolving the actual data of the related objects.
   */
  @Prop({ type: Object, required: true })
  public readonly relatedService!: ResolvesRelatedAsync & ExposesRelatedRecord

  /**
   * Module version
   */
  @Prop({ type: String, required: true })
  public readonly version!: HeroModuleVersion

  /**
   * @see HeroModuleAddonType
   */
  public readonly HeroModuleAddonType = HeroModuleAddonType

  /**
   * @see HeroModuleVersion
   */
  public readonly HeroModuleVersion = HeroModuleVersion

  /**
   * Method to get background image of slide for <ImageForm>
   */
  public get image (): UnresolvedImage | null {
    if (!Object.prototype.hasOwnProperty.call(this._slide, 'backgroundImage')) {
      return null
    }

    // FIXME - even when I check if backgroundImage exist I get console error that backgroundImage can not exist
    // I don't know how to fix this issue properly without type cast in this place
    const slide = this._slide as HeroModuleSlideContent

    if (slide.backgroundImage === null) {
      return null
    }
    return {
      image: slide.backgroundImage
    }
  }

  /**
   * Method to set background image of slide chosen from <ImageForm>
   */
  public set image (image: UnresolvedImage | null) {
    if (!image) {
      const slideCopy = { ...this._slide } as HeroModuleSlideContent
      slideCopy.backgroundImage = null
      this._slide = slideCopy
      return
    }
    this._slide = {
      ...this._slide,
      backgroundImage: image.image
    }
  }

  /**
   * Adds an addon with given type
   * @param addonType - type of addon to add
   * @param index - number of addon to edit
   */
  public addAddon (addonType: HeroModuleAddonType, index: number): void {
    const addonsCopy = [...this._slide.addons]

    switch (addonType) {
      case HeroModuleAddonType.Heading:
        (addonsCopy[index] as HeadingAddon) = {
          type: HeroModuleAddonType.Heading,
          content: '',
          variant: DEFAULT_VARIANT,
          id: uuid().toString()
        }
        break
      case HeroModuleAddonType.Link:
        (addonsCopy[index] as LinkAddon) = {
          type: HeroModuleAddonType.Link,
          align: undefined,
          alignDesktop: undefined,
          link: {
            label: '',
            target: ''
          },
          id: uuid().toString()
        }
        break
      case HeroModuleAddonType.Text:
        (addonsCopy[index] as TextAddon) = {
          type: HeroModuleAddonType.Text,
          content: '',
          id: uuid().toString()
        }
        break
      case HeroModuleAddonType.Search:
        (addonsCopy[index] as SearchAddon) = {
          type: HeroModuleAddonType.Search,
          searchResultsPage: {
            label: '',
            target: ''
          },
          id: uuid().toString()
        }
        break
    }

    this._slide.addons = addonsCopy
  }

  /**
   * Returns applicable form component for the passed-in `HeroModuleAddonType`.
   *
   * @param addonType
   */
  public getAddonComponent (addonType: HeroModuleAddonType): AsyncComponent | undefined {
    const component = heroModuleAddonTypeFormComponentRegistry[addonType]

    if (typeof component === 'undefined') {
      log(`HeroModuleForm.getAddonComponent(): There's no component binding for the passed-in [${addonType}] HeroModuleAddonType!`, 'warn')
      return
    }

    return component
  }

  /**
   * Prepares empty addon
   */
  public prepareAddon (): HeroModuleAddon<HeroModuleAddonType> {
    return {
      type: HeroModuleAddonType.Link,
      id: uuid().toString()
    }
  }
}

export default HeroSlideForm
