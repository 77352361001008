








































































import { Component, Watch } from 'vue-property-decorator'
import { DashmixBoxTabItem, DashmixIconName } from '@movecloser/ui-core'

import { ContainerWidth, FormFieldset, SlideTabLabel } from '../../../atoms'
import { Iterator, MapSelector } from '../../../molecules'

import { AbstractModuleForm } from '../../_abstract'
import {
  HeroModule,
  HeroModuleBaseContent,
  HeroModuleSlideContent,
  HeroModuleVersion
} from '../Hero.contracts'

import { heroContentFactory } from '../Hero.factory'
import { HeroSlideForm } from './partials/HeroSlideForm.vue'

/**
 * Form component to collect data for HeroModule
 * @see HeroModuleBasicVersionContent
 * @see HeroModuleSliderVersionContent
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl> (edited)
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Component({
  name: 'HeroModuleForm',
  components: {
    HeroSlideForm,
    FormFieldset,
    Iterator,
    MapSelector
  }
})
export class HeroModuleForm extends AbstractModuleForm<HeroModule> {
  public initialContent: HeroModuleBaseContent = heroContentFactory()

  /**
   * Initial active tab
   */
  public activeTab: string = '0'

  // Helpers - dictionary values used in template
  /**
  * @see ContainerWidth
  */
  public readonly ContainerWidth = ContainerWidth

  /**
   * @see DashmixIconName
   */
  public readonly DashmixIconName = DashmixIconName

  /**
   * @see HeroModuleVersion
   */
  public readonly HeroModuleVersion = HeroModuleVersion

  /**
   * Determines if chosen version has one slide
   */
  public get isSingleSlide (): boolean {
    return this._version === HeroModuleVersion.Basic || this._version === HeroModuleVersion.Animation
  }

  /**
   * Slides as tabs
   */
  public get slideTabs (): DashmixBoxTabItem[] {
    if (typeof this._content.slides === 'undefined' || !Array.isArray(this._content.slides)) {
      throw new Error('[HeroModuleForm]: Try to get not existing slide list!')
    }

    return [
      ...this._content.slides.map((slide, index) => {
        return {
          props: {
            slide: this._content.slides[index],
            index: index
          },
          tab: {
            id: `${index}`,
            disabled: false,
            label: SlideTabLabel,
            props: {
              tabIndex: index,
              label: `${this.$t('forms.Hero.tab.slide')} ${index + 1}`,
              onSlideRemove: this.removeSlide
            }
          }
        }
      })
    ]
  }

  /**
   * Method to add new slide to slide list
   * @see HeroModuleSliderVersionContent.slides
   */
  public addNewSlide (): void {
    if (typeof this._content.slides === 'undefined' || !Array.isArray(this._content.slides)) {
      throw new Error('HeroModuleForm.addNewSlide(): Try to add slide to not existing slide list!')
    }

    this._content.slides.push(this.createNewSlide())
  }

  /**
   * Method to create object of new slide
   * @return HeroModuleSlideContent
   */
  public createNewSlide (): HeroModuleSlideContent {
    return {
      addons: [],
      backgroundImage: null
    }
  }

  /**
   * Removes slide from `_content.slides` by its index
   * @param index - list index of element to delete
   */
  private removeSlide (index: number): void {
    if (typeof this._content.slides === 'undefined' || !Array.isArray(this._content.slides)) {
      throw new Error('HeroModuleForm.removeSlide(): Try to remove slide form not existing slide list!')
    }

    this._content.slides.splice(index, 1)

    if (this.activeTab !== '0') {
      this.activeTab = (index - 1).toString()
    }
  }

  /**
   * Replaces current slide with updated item
   * @param slide - new slide info
   * @param index - list index of element to edit
   */
  public updateSlide (slide: HeroModuleSlideContent, index: number): void {
    if (typeof this._content.slides === 'undefined' || !Array.isArray(this._content.slides)) {
      throw new Error('HeroModuleForm.updateSlide(): Try to update slide in not existing slide list!')
    }

    const contentSlideCopy = [...this._content.slides]

    contentSlideCopy[index] = slide
    this._content.slides = contentSlideCopy
  }

  /**
   * Update content according to chosen version
   * @see HeroModuleVersion
   * @see HeroModuleBasicVersionContent
   * @see HeroModuleSliderVersionContent
   *
   * @param newVersion - new component version
   */
  @Watch('_version')
  private onVersionChange (newVersion: HeroModuleVersion): void {
    switch (newVersion) {
      case HeroModuleVersion.Basic:
        // when chosen version is `basic` removes slide list and add addons and backgroundImage
        this._content = {
          containerWidth: this._content.containerWidth,
          showBreadcrumbs: this._content.showBreadcrumbs,
          addons: [],
          backgroundImage: null
        }
        break
      case HeroModuleVersion.Slider:
        // when chosen version is `slider` removes content data (backgroundImage and addons)
        // and add slides list with current content data (backgroundImage and addons) as first slide
        this._content = {
          containerWidth: this._content.containerWidth,
          showBreadcrumbs: this._content.showBreadcrumbs,
          slides: [
            {
              backgroundImage: this._content.backgroundImage,
              addons: this._content.addons
            }
          ]
        }
        break
      case HeroModuleVersion.Animation:
        this._content = {
          containerWidth: this._content.containerWidth,
          showBreadcrumbs: this._content.showBreadcrumbs,
          addons: [],
          gallery: null
        }
        break
      default:
        throw new Error(`[HeroModuleForm.onVersionChange()]: Not supported version of Hero module. Chosen version: ${newVersion}.`)
    }
  }
}

export default HeroModuleForm
